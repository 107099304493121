html,
body {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.02em;
  background-color: #f6f6f6;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.App-header {
  h1 {
    font-family: "Archivo Black", sans-serif;
    font-weight: 300;
    font-style: normal;
  }

  h2 {
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.02em;

    .highlight {
      font-weight: 100;
    }
  }
}

.App-body {
  padding: 24px;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  h3 {
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.02em;
  }

  p {
    font-weight: 100;
  }

  .footer {
    font-weight: 300;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
}
